<template>
  <div :class="['d-flex flex-wrap align-center', { 'global-full-width': isMobile }]">
    <v-row no-gutters :class="['rounded border-secondary mb-2 flex-wrap mr-sm-2', { 'global-full-width': isMobile }]">
      <v-col class="pa-0" cols="6">
        <v-sheet max-width="200px" class="global-relative filter-type">
          <c-select
            :value="filter"
            :items="filterList"
            :loading="isLoading"
            item-value="key"
            class="campaign-select"
            item-text="name"
            width="0"
            :outlined="false"
            return-object
            item-slot
            selection-slot
            :height="isMobile ? 40 : 30"
            background-color="transparent"
            hide-details
            autofocus
            @change="setNewFilter($event)"
          >
            <template #selection="{ item }">
              {{ $t(item?.name) }}
            </template>
            <template #item="{ item }">
              <span>{{ $t(item?.name) }}</span>
            </template>
          </c-select>
        </v-sheet>
      </v-col>
      <v-col class="pa-0" cols="6">
        <c-select
          :value="applyFilter.event"
          :items="currentFilter.filter"
          :loading="isLoading"
          :outlined="false"
          :item-text="currentFilter.itemText"
          item-value="value"
          class="campaign-select"
          return-object
          item-slot
          selection-slot
          :height="isMobile ? 40 : 30"
          background-color="white"
          label-bold
          border-color="secondary"
          hide-details
          autofocus
          @change="setFilters(currentFilter.key, $event)"
        >
          <template #selection="{ item }">
            {{ $t(item[currentFilter.itemText]) }}
          </template>
          <template #item="{ item }">
            <span>{{ $t(item[currentFilter.itemText]) }}</span>
          </template>
        </c-select>
      </v-col>
    </v-row>
    <c-btn :height="30" color="primary" class="mb-3 " :label="$t('campaign.apply')" @click="applyFilters()" />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'CampaignFiltersForm',
    components: {
      CBtn, CSelect
    },
    props: {
      filterList: {
        type: Array,
        required: true
      },
      editingItem: {
        type: Object,
        default: () => ({})
      },
      isEditing: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        filter: null,
        applyFilter: {}
      }
    },
    computed: {
      ...mapState('campaigns', ['statuses', 'pricingModels']),
      ...mapGetters('settings', ['getAdFormatTypesByAdNetwork']),
      ...mapGetters('approvables', ['approvalStatusTypes']),
      ...mapState('campaigns', ['campaignGroups', 'groupId']),
      ...mapState('settings', ['adNetwork']),
      computedFiltersList() {
        const filters = [
          {
            key: 'adFormat',
            value: null,
            filter: this.computedAdFormats,
            itemText: 'label'
          },
          {
            key: 'pricingModel',
            value: null,
            filter: this.pricingModels,
            itemText: 'label'
          },
          {
            key: 'latestApproval',
            value: null,
            filter: this.approvalStatusTypes,
            itemText: 'label'
          },
          {
            key: 'campaignGroupId',
            value: null,
            filter: this.campaignGroups,
            itemText: 'name'
          }
        ]
        return filters
      },
      currentFilter() {
        if (this.isEditing && this.editingItem != null) {
          const filterFound = this.computedFiltersList.find((filterItem) => filterItem.key === this.editingItem.key)
          if (filterFound) {
            filterFound.value = this.editingItem.value.value
            const filteredValue = filterFound.filter.find((item) => item.value === this.editingItem.value.value)
            this.setFilters(filterFound.key, filteredValue)
            return filterFound
          }
        }
        return this.computedFiltersList.find((filterItem) => filterItem.key === this.filter.key)
      },
      computedAdFormats() {
        return this.getAdFormatTypesByAdNetwork.map((type) => {
          const labelValue = type === adFormats.IN_PAGE_AD && this.adNetwork.settings.renameInPageFormat ? 'tg_interstitial' : type
          return {
            value: type,
            label: this.$t(`main.ad_format_types.${labelValue}`)
          }
        })
      },
      defaultFilter() {
        return this.filterList[0]
      },
      breakpoint() {
        return this.$vuetify.breakpoint
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    },
    watch: {
      groupId(newValue) {
        if (!newValue) return

        this.setFilters('campaignGroupId', newValue)
      }
    },
    created() {
      this.fetchCampaignGroups()
      this.filter = this.defaultFilter
    },
    methods: {
      ...mapActions('campaigns', ['fetchCampaignGroups']),
      setNewFilter(filterKey) {
        this.filter = filterKey
        this.setFilters(this.currentFilter.key, this.currentFilter.value)
      },
      setFilters(name, event) {
        this.applyFilter = { name, event }
        if (event?.value) {
          gtmPush({
            event: GTM_EVENTS.CAMPAIGNS_FILTERS,
            name: this.filter.name,
            value: event.value
          })
        }
      },
      applyFilters() {
        this.$emit('set-filters', this.applyFilter)
      }
    }
  }
</script>

<style scoped lang="scss">
  .filter-type:after{
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% - 16px);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--v-secondary-base);
  }
  .campaign-select ::v-deep .v-select__selection{
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
    text-wrap: wrap;
    word-wrap: normal;
  }

</style>
